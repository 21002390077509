import React from 'react'
import styled from '@emotion/styled'

const WhoAmI =  styled.div`
	grid-column: 3 / span 5;
	max-width: 425px;
	line-height: 1.5;
	margin-top: -7rem;
	@media screen and (max-width: 1023px){
		padding-left: 16px;
		padding-right: 16px;
		grid-row: 3;
		margin-top: 0;
	}
	@media screen and (max-width: 767px){
		grid-column: 1 / span 4;
	}
	@media screen and (min-width: 768px) and (max-width: 1023px){
		grid-column: 1 / span 4;
		//margin-top: -5rem;
	}
	p{
		font-size: 1em;
		a{
			text-decoration: none;
			display: inline-block;
			position: relative;
			color: var(--c-black);
			transition: 300ms;
			&:after{
				content: "";
				display: block;
				bottom: 0;
				left: 0;
				height: 2px;
				width: 100%;
				background: var(--c-tertiary);
				transition: 300ms;
				position: absolute;
				z-index: -1;
			}
			&:hover{
				color: white;
				&:after{
					height: 100%;
				}
			}
		}
	}
`

const Me = () => {
	return(
		<WhoAmI>
			<p>As a <a href="http://bradfrost.com/blog/post/frontend-design/" target="_blank" rel="noopener noreferrer">Front-end Designer</a> who has experience working with designers, developers even stakeholders, I’ll help you bring your idea to the next level.</p>

			<p>Wanna talk? or just say hi? Simply just drop me an <a href="mailto:hello@rizqi.im">email</a>.</p>
		</WhoAmI>
	)
}

export default Me