import React, {useState, useEffect} from 'react'
import { graphql } from "gatsby"
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'
import ReactResizeDetector from 'react-resize-detector'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hello from '../components/hello'
import Me from '../components/me'
import Client from '../components/client'

import SVGLine from '../images/line-shape.svg'

import styled from '@emotion/styled'

const Container = styled.div`
	padding-left: 0;
	padding-right: 0;
`
const Rizqi = styled.div`
	grid-column: 7 / span 6;
	text-align: right;
	margin-top: -8rem;
	grid-row: 1;
	@media screen and (max-width: 767px){
			display: none;
			grid-column: 1 / span 4;
			grid-row: 1;
			margin-top: 0;
	}
	@media screen and (min-width: 768px) and (max-width: 1023px){
			display: none;
			grid-column: 5 / span 5;
			grid-row: 1;
			margin-top: 0;
	}
`
const RizqiWrapper = styled.div`
	position: relative;
	display: inline-block;
`
const Gradient =  styled.div`
	opacity: 0.5;
	background-image: linear-gradient(207deg, #44FFC8 1%, #5A5AE5 100%);
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	clip-path: url(#svgPath);
`
const ParallaxProfile = styled(Parallax)`
	clip-path: url(#svgPath);
	margin-bottom: 0;
`
const ParallaxLine = styled(Parallax)`
	position: absolute;
	top: 3.5rem;
	right: 1rem;
	@media screen and (max-width: 767px){
		top: 1.5rem;
	}
	@media screen and (min-width: 768px) and (max-width: 1023px){
		top: 1rem;
	}
`

const IndexPage = (props) => {
	const clients = [
		{
			name: "PR360 Global",
			url: "http://pr360global.com",
			img: props.data.pr360.childImageSharp.fluid,
			width: props.data.pr360.childImageSharp.fluid.presentationWidth
		},
		{
			name: "Bluelounge",
			url: "http://bluelounge.com",
			img: props.data.bluelounge.childImageSharp.fluid,
			width: props.data.bluelounge.childImageSharp.fluid.presentationWidth
		},
		{
			name: "The Little Door",
			url: "http://thelittledoor.com",
			img: props.data.tld.childImageSharp.fluid,
			width: props.data.tld.childImageSharp.fluid.presentationWidth
		},
		{
			name: "RocketOn",
			url: "http://rocketon.app",
			img: props.data.rocketon.childImageSharp.fluid,
			width: props.data.rocketon.childImageSharp.fluid.presentationWidth
		}
	]
	const size = useWindowSize()
	
	return (
		<ParallaxProvider>
			<Layout>
				<SEO />
				<Container className="container">
					<Hello />
					
					<Rizqi>
						<RizqiWrapper>
							<ParallaxProfile y={["88px", "-48px"]} tagOuter="figure" disabled={
								size.width < 1025 ? true : false
							}>
								<Img fixed={props.data.rizqi.childImageSharp.fixed} alt="Rizqi Nizamil Putra" />
							</ParallaxProfile>
							<Gradient></Gradient>
							<ParallaxLine y={[0, -10]} disabled={
								size.width < 769 ? true : false
							}>
								<img src={SVGLine} alt="Blue Line" />
							</ParallaxLine>
							<ReactResizeDetector handleWidth>
								{
									(elementWidth) => {
										
										return(
											<svg height="0" width="0">
												<defs>
													<clipPath id="svgPath" transform={
														elementWidth.width < 468 ? "scale(0.7)" : "scale(1)"
													}>
														<path fill="#000000" d="M157.384352,77.0531437 C60.3060104,118.826248 10.0491258,153.591466 0.884351801,231.553144 C-8.28042221,309.514821 55.3368072,344.837278 119.884352,396.053144 C184.431896,447.26901 212.333163,457.661411 291.384352,462.053144 C370.435541,466.444876 395.385756,470.828365 452.884352,414.553144 C510.382948,358.277922 605.884352,187.553144 464.384352,61.5531437 C322.884352,-64.4468563 254.462693,35.2800392 157.384352,77.0531437 Z"/>
													</clipPath>
												</defs>
											</svg>
										)
									}
								}
							</ReactResizeDetector>
						</RizqiWrapper>
					</Rizqi>
					<Me />
				</Container>
				<Client heading="Helped them live" clients={clients} />
			</Layout>
		</ParallaxProvider>
	)
}

export default IndexPage

// Hook
function useWindowSize() {
	const isClient = typeof window === 'object';
	function getSize() {
	  return {
			width: isClient ? window.innerWidth : undefined,
			height: isClient ? window.innerHeight : undefined
	  };
	}
	const [windowSize, setWindowSize] = useState(getSize);
	useEffect(() => {
	  if (!isClient) {
		return false;
	  }
	  function handleResize() {
		setWindowSize(getSize());
	  }
	  window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // Empty array ensures that effect is only run on mount and unmount
	return windowSize;
}

// Image Rizqi
export const query = graphql`
  query {
    rizqi: file(relativePath: { eq: "rizqi.jpg" }) {
      childImageSharp {
        fixed(width: 542, height: 500) {
          ...GatsbyImageSharpFixed
        }
      }
		},
		pr360: file(relativePath: { eq: "logo-pr360.png" }) {
      childImageSharp {
        fluid(maxWidth: 177) {
					...GatsbyImageSharpFluid_tracedSVG
					presentationWidth
        }
      }
		},
		bluelounge: file(relativePath: { eq: "logo-bluelounge.png" }) {
      childImageSharp {
        fluid(maxWidth: 188) {
					...GatsbyImageSharpFluid_tracedSVG
					presentationWidth
        }
      }
		},
		tld: file(relativePath: { eq: "logo-tld.png" }) {
      childImageSharp {
        fluid(maxWidth: 188) {
					...GatsbyImageSharpFluid_tracedSVG
					presentationWidth
        }
      }
		},
		rocketon: file(relativePath: { eq: "logo-rocketon.png" }) {
      childImageSharp {
        fluid(maxWidth: 169) {
					...GatsbyImageSharpFluid_tracedSVG
					presentationWidth
        }
      }
    }
}`