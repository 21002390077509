import React, { Component } from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

class Client extends Component {
    render() {
        const clients = this.props.clients;

        const Container = styled.div`
            margin-top: 5rem;
            @media screen and (max-width: 767px){
                padding-left: 0;
                padding-right: 0;
            }
        `
        const Heading = styled.h4`
            grid-column: 1 / span 12;
            text-align: center;
            font-size: 0.8125em;
            text-transform: uppercase;
            letter-spacing: 1.39px;
            margin-bottom: 2rem;
            &:last-child{
                margin-top: 2rem;
                color: var(--c-tertiary);
            }
            @media screen and (max-width: 767px){
                grid-column: 1 / span 4;
            }
            @media screen and (min-width: 768px) and (max-width: 1023px){
                grid-column: 1 / span 8;
            }
        `
        const ClientList = styled.ul`
            grid-column: 2 / span 10;
            grid-row: 2;
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media screen and (max-width: 767px){
                flex-wrap: wrap;
                grid-column: 1 / span 4;
            }
            @media screen and (min-width: 768px) and (max-width: 1023px){
                grid-column: 1 / span 8;
                flex-wrap: wrap;
            }
            li{
                filter: grayscale(1);
                transition: 300ms;
                width: 25%;
                @media screen and (max-width: 1023px){
                    width: 50%;
                    padding: 1rem;
                    > a{
                        display: block;
                        max-width: 100%;
                        > .gatsby-image-wrapper{
                            max-width: 100%;
                        }
                    }
                }
                @media screen and (min-width: 768px) and (max-width: 1023px){
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
                &:hover{
                    filter: grayscale(0);
                }
                img{
                    object-fit: contain !important;
                }
            }
        `
        return (
            <Container className="container clientList">
                <Heading>{this.props.heading}</Heading>
                
                <ClientList>
                {
                    clients.map((client, i) => {
                        return(
                            <li key={i}>
                                <a href={client.url} target="blank">
                                    <Img fluid={client.img} alt={client.img} style={{ width: client.width, margin: 'auto', objectFit: 'cover' }} />
                                </a>
                            </li>
                        )
                    })
                }
                </ClientList>

                <Heading>And more...</Heading>
            </Container>
        )
    }
}

export default Client