import React from 'react'
import styled from '@emotion/styled'

const Headline = styled.h1`
	grid-column: 2 / span 6;
	font-size: 16px;
	font-weight: 400;
	padding-top: 3rem;
	grid-row: 1;
	@media screen and (max-width: 1023px){
		padding-top: 0;
		padding-left: 16px;
		padding-right: 16px;
	}
	@media screen and (max-width: 767px){
		grid-column: 1 / span 4;
		grid-row: 2;
		max-width: 425px;
	}
	@media screen and (min-width: 768px) and (max-width: 1023px){
		grid-column: 1 / span 6;
		grid-row: 1;
		position: relative;
		z-index: 2;
	}
	& > span{
		display: block;
		&:first-child{
			font-size: 0.875em;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-weight: 500;
			margin-bottom: 1rem;
		}
		&:last-child{
			font-size: 5em;
			line-height: 1;
			letter-spacing: -2.22px;
			font-weight: 500;
			font-family: var(--f-secondary);
			@media screen and (max-width: 767px){
				font-size: 4em;
			}
			> span{
				color: var(--c-tertiary);
			}
		}
	}
`

const Hello = () => {
	return(
		<Headline>
			<span>Hello, I'm Rizqi,</span>
			<span>I'll help you build things<span>.</span></span>
		</Headline>
	)
}

export default Hello